<style scoped>
.no-border-card .card-footer {
  border-top: 0;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .resourceGrid>>>table.el-table__body td:nth-of-type(1):before {
    content: "Resource Name";
  }

  .resourceGrid>>>table.el-table__body td:nth-of-type(2):before {
    content: "# Courses";
  }

  .resourceGrid>>>table.el-table__body td:nth-of-type(3):before {
    content: "Type";
  }

  .resourceGrid>>>table.el-table__body td:nth-of-type(4):before {
    content: "Available after completion";
  }

  .resourceGrid>>>table.el-table__body td:nth-of-type(5):before {
    content: "Status";
  }

  .resourceGrid>>>table.el-table__body td:nth-of-type(6):before {
    content: "Action";
  }
}
</style>
<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import serverSidePaginationMixin from "../Tables/PaginatedTables/serverSidePaginationMixin";
import "sweetalert2/src/sweetalert2.scss";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { VueEditor } from "vue2-editor";

let timeout = null;
export default {
  name: "resources",
  mixins: [serverSidePaginationMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueEditor
  },
  data() {
    return {
      loading: false,
      selectedFilter: '',
      emailTemplates: [],  // Empty array to store templates dynamically
      selectedTemplate: null,
      content: '',
      subject: '',
      location: [],
      assigncompanies: [],
      manager_count: '',
      all_count: '',
      company_count: '',
      selectedUsers: [], // Selected user IDs
      userOptions: [], // Options for the dropdown
      selectedCompanies: [], // Selected user IDs
      companiesOption: [], // Options for the dropdown
      companysearchQuery: "", // User input for search
      searchQuery: "", // User input for search
    }
  },
  mounted() {
  },
  created() {

    this.$http.get('/manager-count').then(resp => {
      this.manager_count = resp.data.manager_count;
    });
    this.$http.get('/all-count').then(resp => {
      this.all_count = resp.data.all_count;
    });
    this.$http.get('/company-count').then(resp => {
      this.company_count = resp.data.all_company_count;
    });
    this.$http.get('/marketing-templates').then(resp => {
      if (resp.data.success) {
          this.emailTemplates = resp.data.data;  // Populate the emailTemplates array with the response
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to load email templates.",
            icon: "error",
          });
        }
    });
  },
  methods: {
    fetchData(query) {
      console.log('here');
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.$http
            .get('/allcompanies', { params: { query } }) // Use 'params' for query parameters
            .then((resp) => {
              this.companiesOption = resp.data; // Update the userOptions with response data
            })
            .catch((error) => {
              console.error('Error fetching users:', error);
            })
            .finally(() => {
              this.loading = false; // Always stop loading indicator
            });
        }, 400);
      } else {
        this.companiesOption = []; // Reset userOptions if query is empty
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.$http
            .get('/allusers', { params: { query } }) // Use 'params' for query parameters
            .then((resp) => {
              this.userOptions = resp.data; // Update the userOptions with response data
            })
            .catch((error) => {
              console.error('Error fetching users:', error);
            })
            .finally(() => {
              this.loading = false; // Always stop loading indicator
            });
        }, 400);
      } else {
        this.userOptions = []; // Reset userOptions if query is empty
      }
    },
    sendMail() {
      // Select Filter
      const filter = this.selectedFilter;
      if (!filter) {
        Swal.fire({
          title: "Missing!",
          text: "Please Select Any Filter",
          icon: "info"
        });
      }
      // When Selected Users Filter Applied .
      if (filter == 'selectedUsers') {
       this.mailToSelectedUsers();
      }
      // When All Managers Filter Applied
      if(filter==='allManagers'){
       this.mailToAllManagers();
      }
       // When All Managers Filter Applied
      if(filter==='allUsers'){
       this.mailToAllUsers();
      }

      // When Selected Companies Filter Applied
      if(filter=='selectedCompanies'){
      this.mailToSelectedCompanies();
      }
      // When All Companies Filter Applied
      if(filter==='allCompanies'){
       this.mailToAllCompanies();
      }
    },

    mailToSelectedUsers(){

      const data = {
          users: this.selectedUsers,
          subject: this.subject,
          body: this.content,
          template : this.selectedTemplate
        }
        this.$http
          .post('/send-email-selectedusers', { data }) // Use 'params' for query parameters
          .then((resp) => {
            Swal.fire({
              title: "Success!",
              text: resp.data.success,
              icon: "success"
            });
            this.selectedUsers = []; // Selected user IDs
            this.userOptions = []; // Options for the dropdown
            this.searchQuery = "";
            this.content = '';
            this.subject = '';
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              // Extract and format error messages
              const errorMessages = Object.values(error.response.data.errors)
                .flat() // Flatten the nested arrays
                .join('\n'); // Join messages with a new line
              // Display errors in a Swal alert
              Swal.fire({
                title: "Validation Error",
                text: errorMessages,
                icon: "error"
              });
            } console.error('Errors:', error);
          })
          .finally(() => {
            this.loading = false; // Always stop loading indicator
          });
    },
    mailToAllManagers(){
      const data = {
          users: this.allManagers,
          subject: this.subject,
          body: this.content,
          template : this.selectedTemplate
          }
          this.$http
          .post('/send-email-allmanagers', { data }) // Use 'params' for query
          .then((resp) => {
            Swal.fire({
              title: "Success!",
              text: resp.data.success,
              icon: "success"
              });
              this.selectedUsers = []; // Selected user IDs
              this.userOptions = []; // Options for the dropdown
              this.searchQuery = "";
              this.content = '';
              this.subject = '';
              })
              .catch((error) => {
                if (error.response && error.response.data.errors) {
                  // Extract and format error messages
                  const errorMessages = Object.values(error.response.data.errors)
                  .flat() // Flatten the nested arrays
                  .join('\n'); // Join messages with a new line
                  // Display errors in a Swal alert
                  Swal.fire({
                    title: "Validation Error",
                    text: errorMessages,
                    icon: "error"
                    });
                    } console.error('Errors:', error);
                    })
                    .finally(() => {
                      this.loading = false; // Always stop loading indicator
                  });
    },
    mailToAllUsers(){
      const data = {
          users: this.allusers,
          subject: this.subject,
          body: this.content,
          template : this.selectedTemplate
          }
          this.$http
          .post('/send-email-all-users', { data }) // Use 'params' for query
          .then((resp) => {
            Swal.fire({
              title: "Success!",
              text: resp.data.success,
              icon: "success"
              });
              this.selectedUsers = []; // Selected user IDs
              this.userOptions = []; // Options for the dropdown
              this.searchQuery = "";
              this.content = '';
              this.subject = '';
              })
              .catch((error) => {
                if (error.response && error.response.data.errors) {
                  // Extract and format error messages
                  const errorMessages = Object.values(error.response.data.errors)
                  .flat() // Flatten the nested arrays
                  .join('\n'); // Join messages with a new line
                  // Display errors in a Swal alert
                  Swal.fire({
                    title: "Validation Error",
                    text: errorMessages,
                    icon: "error"
                    });
                    } console.error('Errors:', error);
                    })
                    .finally(() => {
                      this.loading = false; // Always stop loading indicator
                      });
    },
    mailToSelectedCompanies(){
      const data = {
          companies: this.selectedCompanies,
          subject: this.subject,
          body: this.content,
          template : this.selectedTemplate
          }
          this.$http
          .post('/send-email-selected-companies', { data }) // Use 'params' for query
          .then((resp) => {
             Swal.fire({
              title: "Success!",
              text: resp.data.success,
              icon: "success"
              });
              this.selectedCompanies = []; // Selected user IDs
              this.companiesOption = []; // Options for the dropdown
              this.searchQuery = "";
              this.content = '';
              this.subject = '';
              })
              .catch((error) => {
                if (error.response && error.response.data.errors) {
                  // Extract and format error messages
                  const errorMessages = Object.values(error.response.data.errors)
                  .flat() // Flatten the nested arrays
                  .join('\n'); // Join messages with a new line
                  // Display errors in a Swal alert
                  Swal.fire({
                    title: "Validation Error",
                    text: errorMessages,
                    icon: "error"
                    });
                    } console.error('Errors:', error);
                    })
                    .finally(() => {
                      this.loading = false; // Always stop loading indicator
                      });
    },
    mailToAllCompanies(){
      const data = {
          subject: this.subject,
          body: this.content,
          template : this.selectedTemplate
          }
          this.$http
          .post('/send-email-all-companies', { data }) // Use 'params' for query
          .then((resp) => {
             Swal.fire({
              title: "Success!",
              text: resp.data.success,
              icon: "success"
              });
              this.selectedCompanies = []; // Selected user IDs
              this.companiesOption = []; // Options for the dropdown
              this.searchQuery = "";
              this.content = '';
              this.subject = '';
              })
              .catch((error) => {
                if (error.response && error.response.data.errors) {
                  // Extract and format error messages
                  const errorMessages = Object.values(error.response.data.errors)
                  .flat() // Flatten the nested arrays
                  .join('\n'); // Join messages with a new line
                  // Display errors in a Swal alert
                  Swal.fire({
                    title: "Validation Error",
                    text: errorMessages,
                    icon: "error"
                    });
                    } console.error('Errors:', error);
                    })
                    .finally(() => {
                      this.loading = false; // Always stop loading indicator
                      });
    }



  }
};
</script>
<template>
  <div class="content">
    <div class="container-fluid mt-3">
      <card class="no-border-card" footer-classes="pb-2" v-loading.fullscreen.lock="loading">
        <template slot="header">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-12">
              <h2 class="mb-0">Email Sending</h2>
            </div>
          </div>
        </template>
        <div>
          <div class="row mb-3">
            <div class="col-12 d-flex justify-content-between">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="filterOptions" id="allManagers" value="allManagers"
                  v-model="selectedFilter" @change="applyFilter" />
                <label class="form-check-label" for="allManagers">All Managers</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="filterOptions" id="allCompanies" value="allCompanies"
                  v-model="selectedFilter" @change="applyFilter" />
                <label class="form-check-label" for="allCompanies">All Companies</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="filterOptions" id="allUsers" value="allUsers"
                  v-model="selectedFilter" @change="applyFilter" />
                <label class="form-check-label" for="allUsers">All Users</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="filterOptions" id="selectedUsers"
                  value="selectedUsers" v-model="selectedFilter" @change="applyFilter" />
                <label class="form-check-label" for="selectedUsers">Selected Users</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="filterOptions" id="selectedCompanies"
                  value="selectedCompanies" v-model="selectedFilter" @change="applyFilter" />
                <label class="form-check-label" for="selectedCompanies">Selected Companies</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="selectedFilter == 'selectedCompanies'">
            <div class="col-md-8">
              <base-input label="Select Companies">
                <el-select style="width: 100%" filterable multiple placeholder="Select Company"
                 remote
                 reserve-keyword
                 v-model="selectedCompanies"
                 :remote-method="fetchData"
                 >

                  <el-option v-for="(option, index) in companiesOption" :key="'company' + index" :value="option.id"
                    :label="option.name">
                  </el-option>
                </el-select>
              </base-input>

            </div>

          </div>
          <div class="row mb-2" v-if="selectedFilter == 'allManagers'">
            <div class="col-12">
              <strong>Managers : </strong> {{ manager_count }}
            </div>
          </div>
          <div class="row mb-2" v-if="selectedFilter == 'allCompanies'">
            <div class="col-12">
              <strong>Companies : </strong> {{ company_count }}
            </div>
          </div>
          <div class="row mb-2" v-if="selectedFilter == 'allUsers'">
            <div class="col-12">
              <strong>Total Users : </strong> {{ all_count }}
            </div>
          </div>
          <div class="row mb-2" v-if="selectedFilter == 'selectedUsers'">
            <div class="col-md-8">
              <base-input label="Select Users">
                <el-select style="width: 100%" filterable multiple remote reserve-keyword placeholder="Select Users"
                  v-model="selectedUsers" :remote-method="remoteMethod">
                  <!-- :loading="loading" -->
                  <el-option v-for="(option, index) in userOptions" :key="'user' + index" :value="option.id"
                    :label="option.full_name">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <base-input label="Subject">
                <el-input v-model="subject" placeholder="Enter subject" class="input-primary"></el-input>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <label for="">Message</label>
              <vue-editor v-model="content"></vue-editor>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <base-input label="Email Template">
                <el-select filterable placeholder="Select Template" v-model="selectedTemplate">
                  <el-option v-for="option in emailTemplates" class="select-primary" :value="option.id"
                    :label="option.template_name" :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button @click.prevent="sendMail()" class="btn base-button btn-default px-3">Submit</button>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
